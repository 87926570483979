<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import UserTableCard from "@/components/dashboard/user/user-table-card.vue";
import DataLoader from "@/components/data-loader.vue";
import "@vueform/multiselect/themes/default.css";
import FilterForm from "@/components/dashboard/filter-form.vue";
import API from "@/plugins/axios-method";
import LoadingModal from "@/components/loading-modal.vue";
import ClientPagination from "../../../components/client-pagination.vue";
export default {
  page: {
    title: "Adhérents",
    meta: [{ name: "description", content: 'User Management' }],
  },
  components: {
    ClientPagination,
    LoadingModal,
    FilterForm,
    DataLoader,
    UserTableCard,
    Layout,
    PageHeader
  },
  data() {
    return {
      users: [],
      showDataLoader: false,
      page: 1,
      totalItems: 0,
      totalPages: 0,
      isSubmitted: false,
      searchModal: null,
      searchBody: null,
      totalResults: 0
    }
  },
  mounted() {
  },
  methods: {
    submitFilter(payload){
      this.users = []
      this.isSubmitted = true
      API.post('/coordinator/search', payload)
          .then(response => {
            const {totalResults, page, totalPages} = response.data
            this.totalResults = totalResults
            this.page = page
            this.totalPages = totalPages
            response.data.searchResults[0].users.map(u => (
                this.users.push(u)
            ))
          })
          .finally(() => {
            this.searchBody = payload
            this.isSubmitted = false
          })
    },
    updateLoader(){
      this.showDataLoader = false
    },
    openSearchModal(){
      this.searchModal = true
    },
    closeSearchModal() {
      this.searchModal = false
    },
    changePage(newPage){
      this.users = []
      this.isSubmitted = true

      API.post('/admin/search', {...this.searchBody, page: newPage})
          .then(response => {
            const {totalResults, page, uniqId, totalPages} = response.data
            this.totalPages = totalPages
            this.searchId = uniqId
            this.totalResults = totalResults
            this.page = page
            response.data.searchResults[0]?.users.map(u => (
                this.users.push(u)
            ))
          })
          .finally(() => {
            this.isSubmitted = false
          })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Recherche des adhérents'"
        :show-search="false"
        :button-title="'Rechercher'"
        @clickButton="openSearchModal"
    />
    <filter-form @filterSubmitted="submitFilter" @cities-loaded="updateLoader" v-model="searchModal" @closeSearchModal="closeSearchModal"/>
    <loading-modal v-model="isSubmitted"/>
    <DataLoader v-if="showDataLoader"/>
    <section v-if="users.length > 0 && !showDataLoader">
      <div class="">
        <div class="card">
          <div class="card-body">
            <UserTableCard
                :users="users"
                :page-name="'members'"
            />
            <ClientPagination @changePage="changePage" isSearch :total-pages="totalPages" :current-page="page"/>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>